import { Typography, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Stack } from '@mui/system';
import Head from 'next/head';
import Link from 'next/link';

export default function Home() {
  return (
    <>
      <Head>
        <title>Jondonero Family</title>
      </Head>
      <main>
        <Stack
          spacing={2}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <Typography variant="h3" fontSize={42}>
            Jondonero Family
          </Typography>
          <Typography variant="subtitle1" fontSize={20} color={grey[700]}>
            Be up to date with the Jondonero Family.
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="outlined"
              color="primary"
              component="a"
              LinkComponent={Link}
              href="/login"
            >
              Log In
            </Button>
            <Button
              component="a"
              LinkComponent={Link}
              href="/registration"
              variant="contained"
              color="primary"
            >
              Register
            </Button>
          </Stack>
        </Stack>
      </main>
    </>
  );
}
